body {
  font-size: .875rem;
}

.App {

  /*
  * Sidebar
  */

  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 48px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);

    @media (max-width: 767.98px) {
      top: 5rem;
    }

    &-sticky {
      position: relative;
      top: 0;
      height: calc(100vh - 48px);
      padding-top: .5rem;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .nav-link {
      font-weight: 500;
      color: #333;
    
      &.active {
        color: #2470dc;
      }
    }

    &-heading {
      font-size: .75rem;
      text-transform: uppercase;
    }
  }

  /*
  * Navbar
  */

  .navbar {

    &-brand {
      padding-top: .75rem;
      padding-bottom: .75rem;
      font-size: 1rem;
      background-color: rgba(0, 0, 0, .25);
      box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
    }

    &-toggler {
      top: .25rem;
      right: 1rem;
    }

    .form-control {
      padding: .75rem 1rem;
      border-width: 0;
      border-radius: 0;
      
      &-dark {
        color: #fff;
        background-color: rgba(255, 255, 255, .1);
        border-color: rgba(255, 255, 255, .1);
        
        &:focus {
          border-color: transparent;
          box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
        }
      }
    }
  }
}